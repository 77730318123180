import { CollectType } from '@dltru/dfa-models'
import { Button, ConditionsModal } from '@dltru/dfa-ui'
import { FC, useState } from 'react'

import {
    acceptedBuyingText,
    acceptedSellingText,
    buyingText,
    conditionAccountText,
    conditionBankAccountText,
    directEmissionText,
    platformEmissionText,
    sellingText,
} from './resources'

interface IComponentProps {
    isBuying?: boolean
    buyByIssuer?: boolean
    collectType?: CollectType
    isSecond?: boolean
    accepted?: boolean
    isEmissionDirect?: boolean
}
export const ConditionTradeLink: FC<IComponentProps> = ({
    isBuying,
    buyByIssuer,
    collectType,
    isSecond,
    accepted,
    isEmissionDirect,
}) => {
    const [isConditionModalVisible, setIsConditionModalVisible] = useState(false)

    let text = collectType === CollectType.account ? conditionAccountText : conditionBankAccountText

    if (isEmissionDirect !== undefined) {
        text = isEmissionDirect ? directEmissionText : platformEmissionText
    }

    if (isSecond && accepted) {
        text = isBuying ? acceptedBuyingText : acceptedSellingText
    }

    if (isSecond && !accepted) {
        text = isBuying ? buyingText : sellingText
    }

    const openConditionModal = (e: React.SyntheticEvent) => {
        e.preventDefault()
        setIsConditionModalVisible(true)
    }

    const mainLinkText = isSecond
        ? `Совершая ${isBuying ? 'покупку' : 'продажу'}, вы соглашаетесь с `
        : 'Подтверждая заявку, вы соглашаетесь с'

    return (
        <>
            {mainLinkText}
            <Button type="linkText" onClick={openConditionModal}>
                условиями
            </Button>
            .
            <ConditionsModal
                isModalVisible={isConditionModalVisible}
                setIsModalVisible={setIsConditionModalVisible}
                title="Условия"
                text={text}
                buyByIssuer={buyByIssuer}
            />
        </>
    )
}
