export const conditionAccountText = (
    <>
        <p>
            Настоящая Заявка на приобретение направляется в соответствии с пунктом 10.22 Правил в
            целях заключения Сделки размещения на условиях, указанных в заполненной Пользователем
            электронной форме «Заявка на приобретение».
        </p>
        <p>
            Настоящим Пользователь направляет предусмотренное пунктом 10.25 Правил Поручение о
            перечислении на Счет аналитического учета Эмитента или Поручение о перечислении на
            Банковский счет Эмитента (в зависимости от того, какой способ осуществления расчетов
            установлен Решением о выпуске ЦФА) в целях исполнения обязательства Пользователя по
            уплате стоимости ЦФА на сумму, соответствующую стоимости таких ЦФА и указанную в графе
            «Стоимость активов» электронной формы «Заявка на приобретение».
        </p>
        <p>
            Заявка на приобретение и указанное выше поручение считаются направленными с момента
            нажатия кнопки «Подтвердить заявку».
        </p>
        <p>
            Все термины, встречающиеся в тексте Заявки на приобретение с заглавной буквы,
            применяются в значении, предусмотренном правилами информационной системы Общества с
            ограниченной ответственностью «Системы распределенного реестра».
        </p>
    </>
)
export const conditionBankAccountText = (
    <>
        <p>Нажимая кнопку «Подтвердить заявку», Пользователь соглашается со следующим:</p>
        <p>
            1. Настоящая Заявка на покупку №876543 направляется в соответствии с пунктом 12.5.1
            Правил и в течение срока её размещения на Витрине предложений будет доступна для полного
            или частичного акцепта другими Пользователями.
        </p>
        <p>2. С момента направления Пользователем Заявки:</p>
        <p>
            2.1. денежные средства, принадлежащие Пользователю, в размере общей стоимости покупаемых
            ЦФА не могут быть использованы Пользователем в каких-либо иных целях, не связанных с
            покупкой таких ЦФА.
        </p>
        <p>
            2.2. денежные средства, принадлежащие Пользователю, в размере суммы Комиссии за полный
            акцепт Заявки не могут быть использованы Пользователем в каких-либо целях, не связанных
            с уплатой такой Комиссии, в соответствии с пунктом 12.22 Правил.
        </p>
        <p>
            3. Исполнение Договора купли-продажи ЦФА и уплата Пользователем Комиссий осуществляется
            без дополнительного волеизъявления Пользователя с использованием информационных
            технологий.
        </p>
        <p>
            4. Термины, используемые с заглавной буквы, имеют значение, установленное для них в
            Правилах информационной системы ООО «Системы распределенного реестра».
        </p>
    </>
)

export const acceptedBuyingText = (
    <>
        <p>
            Настоящая встречная Заявка на покупку направляется соответствии с пунктом 12.5.2 Правил
            в целях акцепта Заявки на продажу.
        </p>
        <p>
            Настоящим Пользователь направляет предусмотренное подпунктом(i) пункта 12.19.4 Правил
            Поручение о перечислении на Счет аналитического учета Продавца или Поручение о
            перечислении на Банковский счет Продавца(в зависимости от способа осуществления
            расчетов, установленного в Заявке на продажу, размещенной на Витрине предложений) в
            целях уплаты стоимости ЦФА на сумму, соответствующую стоимости таких ЦФА и указанную в
            графе «Стоимость активов» электронной формы «Заявка на покупку».
        </p>
        <p>
            Заявка на покупку и указанное выше поручение считаются направленными с момента нажатия
            кнопки «Подтвердить заявку».
        </p>
        <p>
            Все термины, встречающиеся в тексте Заявки на покупку с заглавной буквы, применяются в
            значении, предусмотренном правилами информационной системы Общества с ограниченной
            ответственностью «Системы распределенного реестра».
        </p>
    </>
)
export const acceptedSellingText = (
    <>
        <p>
            Настоящая встречная Заявка на продажу направляется в соответствии с пунктом 12.5.2
            Правил в целях акцепта Заявки на покупку.
        </p>
        <p>
            Заявка на продажу считается направленной с момента нажатия кнопки «Подтвердить заявку».
        </p>
        <p>
            Все термины, встречающиеся в тексте Заявки на продажу с заглавной буквы, применяются в
            значении, предусмотренном правилами информационной системы Общества с ограниченной
            ответственностью «Системы распределенного реестра».
        </p>
    </>
)

export const buyingText = (
    <>
        <p>
            Настоящая Заявка на покупку направляется в соответствии с пунктом 12.5.1 Правил в целях
            её размещения на Витрине предложений на условиях, указанных в заполненной Пользователем
            электронной форме «Заявка на покупку».
        </p>
        <p>
            Настоящим Пользователь предоставляет предусмотренное подпунктом(i) пункта 12.19.5 Правил
            Согласие на перечисление в целях уплаты стоимости ЦФА по всем Договорам купли - продажи
            ЦФА, которые будут заключены в будущем посредством акцепта(в том числе частичного) такой
            Заявки на покупку.
        </p>
        <p>
            Заявка на покупку считается направленной, а указанное выше Согласие на перечисление
            предоставленным с момента нажатия кнопки «Подтвердить заявку».
        </p>
        <p>
            Все термины, встречающиеся в тексте Заявки на покупку с заглавной буквы, применяются в
            значении, предусмотренном правилами информационной системы Общества с ограниченной
            ответственностью «Системы распределенного реестра».
        </p>
    </>
)
export const sellingText = (
    <>
        <p>
            Настоящая Заявка на продажу направляется в соответствии с пунктом 12.5.1 Правил в целях
            её размещения на Витрине предложений на условиях, указанных в заполненной Пользователем
            электронной форме «Заявка на продажу».
        </p>
        <p>
            Заявка на продажу считается направленной с момента нажатия кнопки «Подтвердить заявку».
        </p>
        <p>
            Все термины, встречающиеся в тексте Заявки на продажу с заглавной буквы, применяются в
            значении, предусмотренном правилами информационной системы Общества с ограниченной
            ответственностью «Системы распределенного реестра».
        </p>
    </>
)

export const directEmissionText = (
    <>
        <p>
            Настоящая Заявка на приобретение направляется в соответствии с пунктом 10.22 Правил в
            целях заключения Сделки размещения в отношении ЦФА с Идентификационным номером выпуска
            ЦФА, указанным в графе «Тикер» формы «Детали покупки», в количестве, указанном в графе
            «Количество, ед.» такой формы «Детали покупки».
        </p>
        <p>
            Заявка на приобретение считается направленной с момента нажатия кнопки «Подтвердить
            заявку».
        </p>
        <p>
            Все термины, встречающиеся в настоящем тексте с заглавной буквы, применяются в значении,
            предусмотренном правилами информационной системы Общества с ограниченной
            ответственностью «Системы распределенного реестра».
        </p>
    </>
)

export const platformEmissionText = (
    <>
        <p>
            Настоящая Заявка на приобретение направляется в соответствии с пунктом 10.22 Правил в
            целях заключения Сделки размещения в отношении ЦФА с Идентификационным номером выпуска
            ЦФА, указанным в графе «Тикер» формы «Детали покупки», в количестве, указанном в графе
            «Количество, ед.» такой формы. Настоящим Пользователь направляет предусмотренное пунктом
            10.25 Правил Поручение о перечислении на Счет аналитического учета Эмитента или
            Поручения о перечислении на Банковский счет Эмитента (в соответствии со способом
            осуществления расчетов, предусмотренным Решением о выпуске ЦФА, согласно пункту 10.42.5
            Правил) в целях исполнения обязательства Пользователя по уплате стоимости ЦФА на сумму,
            соответствующую стоимости таких ЦФА и указанную в графе «Стоимость активов» формы
            «Детали покупки».
        </p>
        <p>
            Заявка на приобретение и указанное выше поручение считаются направленными с момента
            нажатия кнопки «Подтвердить заявку».
        </p>
        <p>
            Все термины, встречающиеся в настоящем тексте с заглавной буквы, применяются в значении,
            предусмотренном правилами информационной системы Общества с ограниченной
            ответственностью «Системы распределенного реестра».
        </p>
    </>
)
